import React, { useState } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

import { getPriceChange } from '../../../utils/functions';
import upArrow from '../../../images/arrows/red-up-arrow.svg';
import downArrow from '../../../images/arrows/down-arrow.svg';
import { DATA_SOURCE_INDEX } from 'utils/constants';
import { Collapse } from 'reactstrap';

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.75rem 0.5rem 0;
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 1rem 0;
  }

  ${ifProp(
    { isInProductFeed: false },
    css`
      @media (min-width: ${prop('theme.breakpoints.md')}) {
        flex: 1 1 30%;
        max-width: 30%;
      }
    `
  )};
`;

const Toggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  ${ifProp(
    '$active',
    css`
      cursor: pointer;
    `
  )}
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${prop('theme.colors.borderGrey')};
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-bottom: 0.375rem;
  }

  span {
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.medium')};
    margin: 0.5rem 0;
  }
`;

function isOverrideProvided(value) {
  return typeof value !== 'undefined' && value !== null && value !== '';
}

const propTypes = {
  dataSource: PropTypes.string,
  fields: PropTypes.array,
  summary: PropTypes.object,
  tradeData: PropTypes.object,
  isInProductFeed: PropTypes.bool
};

const defaultProps = {
  dataSource: '',
  fields: [],
  summary: {},
  tradeData: {},
  isInProductFeed: false
};

function DataColumns({
  dataSource,
  fields,
  summary,
  tradeData,
  isInProductFeed = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const trade = tradeData || {};
  // Added additional checks in here b/c dxfeed often returns 'NaN' when there
  // is no data rather than undefined or null
  const prevDayClosePrice = !isNaN(get(summary, 'prevDayClosePrice', 'N/A'))
    ? get(summary, 'prevDayClosePrice', 'N/A')
    : 'N/A';
  const currentPrice = !isNaN(get(trade, 'price', 'N/A'))
    ? get(trade, 'price', 'N/A')
    : 'N/A';
  const change = getPriceChange(currentPrice, prevDayClosePrice);

  const findDataObject = {
    summary: summary,
    trade: trade,
    change: change
  };

  // This function allows for mapping of all the values and accounting for all the
  // different variations in how the data needs to display -- change is a calculation
  // volume should not have decimal points, dayClosePrice has to have a back-up data
  // value (prevDayClosePrice), etc...
  const mapAndFormatData = (dataType, value, prevValue) => {
    if (value === 'change') {
      return typeof dataType === 'number' && dataType !== 0
        ? dataType.toFixed(2)
        : dataType;
    } else if (value === 'expiration') {
      return summary.expiration || 'N/A';
    } else if (dataType && value && prevValue) {
      return !isNaN(dataType[value])
        ? dataType[value].toFixed(2)
        : !isNaN(dataType[prevValue])
        ? dataType[prevValue].toFixed(2)
        : 0;
    } else if (value === 'openInterest') {
      if (dataSource === DATA_SOURCE_INDEX) {
        return 'n/a';
      } else {
        return !isNaN(dataType[value]) ? dataType[value] : 'N/A';
      }
    } else if (dataType && dataType[value]) {
      if (value === 'dayVolume') {
        return !isNaN(dataType[value]) ? dataType[value] : 0;
      } else {
        return !isNaN(dataType[value]) ? dataType[value].toFixed(2) : 'N/A';
      }
    } else {
      return 0;
    }
  };

  const tripleZeroToNA = (value) => {
    return value === '0.00' ? 'N/A' : value;
  };

  return (
    <DataContainer isInProductFeed={isInProductFeed}>
      <Toggle
        $active={isInProductFeed}
        onClick={() => {
          if (isInProductFeed) {
            setIsOpen((prev) => !prev);
          }
        }}
      >
        <Title>Details</Title>
        {isInProductFeed && (
          <img
            src={isOpen ? upArrow : downArrow}
            className={isOpen ? 'expanded-icon' : 'collapsed-icon'}
          />
        )}
      </Toggle>
      <Collapse isOpen={!isInProductFeed || isOpen}>
        {fields.map((field) => (
          <StyledRow md={4} key={field.dxfeed_value}>
            <span>{field.label}</span>
            {summary && summary.contractExp === 'front' && (
              <span>
                {isOverrideProvided(field.override_1)
                  ? field.override_1
                  : tripleZeroToNA(
                      mapAndFormatData(
                        findDataObject[field.data_type],
                        field.dxfeed_value,
                        field.dxfeed_previous_value
                      )
                    )}
              </span>
            )}
            {summary && summary.contractExp === 'back' && (
              <span>
                {isOverrideProvided(field.override_2)
                  ? field.override_2
                  : tripleZeroToNA(
                      mapAndFormatData(
                        findDataObject[field.data_type],
                        field.dxfeed_value,
                        field.dxfeed_previous_value
                      )
                    )}
              </span>
            )}
          </StyledRow>
        ))}
      </Collapse>
    </DataContainer>
  );
}

DataColumns.propTypes = propTypes;
DataColumns.defaultProps = defaultProps;

export default DataColumns;
