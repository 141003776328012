import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
let ReactApexChart;
if (typeof window !== 'undefined') {
  ReactApexChart = require('react-apexcharts').default;
}

import theme from '../../styles/theme';

const Title = styled.h2`
  line-height: 24px;
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin: 0;
`;

const propTypes = {
  title: PropTypes.string,
  series: PropTypes.array
};

const defaultProps = {
  title: '',
  series: []
};

function LineGraph({ title, series }) {
  const chartOptions = {
    chart: {
      id: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false
      }
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    colors: ['#f242f5', '#f242f5', '#f242f5', '#f242f5', '#f242f5'],
    tooltip: {
      style: {
        fontFamily: theme.fonts.default
      },
      x: {
        show: false,
        format: 'MMM d'
      }
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      labels: {
        show: false
      }
    }
  };
  return (
    <>
      <Title>{title}</Title>
      {ReactApexChart && (
        <ReactApexChart
          type='line'
          series={[{ name: title, data: series }]}
          height='140px'
          style={{
            marginLeft: '-1rem'
          }}
          options={chartOptions}
        />
      )}
    </>
  );
}

LineGraph.propTypes = propTypes;
LineGraph.defaultProps = defaultProps;

export default LineGraph;
