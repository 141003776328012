import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MonthContext from '../../context/MonthContext';
import Panel from './Panel';
import { findContractExpirationDate } from '../../utils/functions';
import { DATA_SOURCE_FUTURES, MONTH_INDICES } from '../../utils/constants';
import DataColumns from '../Widgets/PriceMovement/DataColumns';
import CandlestickGraph from '../Widgets/PriceMovement/CandlestickGraph';
import dayjs from '../../utils/dayjs';

const Container = styled.div`
  margin: 0 24px 8px;
`;

const PanelContainer = styled.div`
  margin-bottom: 24px;
`;

const propTypes = {
  summaryTradeData: PropTypes.object,
  symbol: PropTypes.string,
  symbolObject: PropTypes.object,
  activeSymbol: PropTypes.string,
  dataSource: PropTypes.string
};

const defaultProps = {
  summaryTradeData: {},
  symbol: '',
  symbolObject: {},
  activeSymbol: '',
  dataSource: ''
};

function SingleProductFeed({
  summaryTradeData,
  symbol,
  symbolObject,
  activeSymbol,
  dataSource
}) {
  const { selectedMonth } = useContext(MonthContext);
  const productInfo =
    symbolObject[dataSource === DATA_SOURCE_FUTURES ? symbol : activeSymbol];
  const productData = summaryTradeData[activeSymbol];
  const summary = productData && productData.Summary;

  if (selectedMonth && summary && summary.eventSymbol === activeSymbol) {
    Object.assign(summary, {
      contractExp: selectedMonth.contractExp,
      expiration: findContractExpirationDate(
        dayjs()
          .tz()
          .month(MONTH_INDICES[selectedMonth.month]),
        activeSymbol
      ).format('MM/DD/YYYY')
    });
  }

  return (
    <Container>
      <PanelContainer>
        <Panel
          data={productData || { Trade: {}, Profile: {}, Summary: {} }}
          prismicSymbol={
            productInfo ? productInfo.symbol : productData.Profile.eventSymbol
          }
          prismicTitle={
            productInfo ? productInfo.title : productData.Profile.description
          }
          isProductPage
        />
      </PanelContainer>
      <CandlestickGraph
        title='History'
        shouldDisplayAxisLabels={false}
        togglePlacement='top'
        style={{ padding: 0 }}
        symbol={symbol}
      />
      <DataColumns
        isInProductFeed
        dataSource={dataSource}
        fields={productInfo.dataColumns}
        summary={summary}
        tradeData={
          summaryTradeData[activeSymbol] && summaryTradeData[activeSymbol].Trade
        }
      />
    </Container>
  );
}

SingleProductFeed.propTypes = propTypes;
SingleProductFeed.defaultProps = defaultProps;

export default SingleProductFeed;
