import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import styled from 'styled-components';

import LineGraph from './LineGraph';
import MonthContext from '../../context/MonthContext';
import MarketDataContext from '../../context/MarketDataContext';

const Container = styled.div`
  margin: 1.5rem;
`;

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  symbol: PropTypes.string
};

const defaultProps = {
  data: [],
  symbol: ''
};

function QuotePageFeed({ data, symbol }) {
  const { historicalMonthData } = useContext(MarketDataContext);
  const { selectedMonth } = useContext(MonthContext);
  const activeSymbol = selectedMonth ? selectedMonth.symbol : symbol;

  const volume = historicalMonthData
    .filter((month) => month.symbol === activeSymbol)
    .flatMap((month) => month.data)
    .filter((dayData) => isNumber(dayData.volume))
    .map((dayData) => ({
      x: dayData.time,
      y: dayData.volume
    }))
    .sort((a, b) => (a.x < b.x ? 1 : -1));

  const openInterest = historicalMonthData
    .filter((month) => month.symbol === activeSymbol)
    .flatMap((month) => month.data)
    .filter((dayData) => isNumber(dayData.openInterest))
    .map((dayData) => ({
      x: dayData.time,
      y: dayData.openInterest
    }))
    .sort((a, b) => (a.x < b.x ? 1 : -1));

  return (
    <Container>
      <LineGraph title='Volume' series={volume} />
      <LineGraph title='Open Interest' series={openInterest} />
    </Container>
  );
}

QuotePageFeed.propTypes = propTypes;
QuotePageFeed.defaultProps = defaultProps;

export default QuotePageFeed;
