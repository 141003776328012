import React, { useContext, useEffect, useState } from 'react';
import { isNumber } from 'lodash';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import MonthContext from '../../../context/MonthContext';
import SiteSettingsContext from '../../../context/SiteSettingsContext';
import theme from 'styles/theme';
import MultiButtonToggle from '../../MultiButtonToggle';
import dayjs from 'utils/dayjs';
import SpinnerContainer from '../../SpinnerContainer';
import MarketDataContext from '../../../context/MarketDataContext';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX,
  FUTURES_TO_INDEX_SYMBOL
} from '../../../utils/constants';

let ReactApexChart;
if (typeof window !== 'undefined') {
  ReactApexChart = require('react-apexcharts').default;
}

const ONE_DAY = 1;
const THIRTY_DAYS = 30;

const CandlestickContainer = styled.div`
  padding: 0.625rem 1rem;
  margin-right: 1rem;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  line-height: 24px;
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${ifProp(
    { $placement: 'top' },
    css`
      justify-content: flex-start;
      margin-top: 0.5rem;

      button {
        font-size: 0.75rem;
      }
    `
  )}
`;

const propTypes = {
  title: PropTypes.string,
  shouldDisplayAxisLabels: PropTypes.bool,
  togglePlacement: PropTypes.string,
  style: PropTypes.object,
  symbol: PropTypes.string
};

const defaultProps = {
  title: null,
  shouldDisplayAxisLabels: true,
  togglePlacement: 'bottom',
  style: {},
  symbol: ''
};

function CandlestickGraph({
  title,
  shouldDisplayAxisLabels,
  togglePlacement,
  style,
  symbol
}) {
  const { selectedMonth } = useContext(MonthContext);
  const siteSettings = useContext(SiteSettingsContext);
  const { candleData, isCandleDataLoading, historicalMonthData } = useContext(
    MarketDataContext
  );
  const [graphMode, setGraphMode] = useState(ONE_DAY);

  let graphData = [];

  // Determine whether we show index data or futures data based on global site setting value.
  const historicalDataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].historical_graph_data_source) ||
    DATA_SOURCE_INDEX;

  const historicalActiveSymbol =
    historicalDataSource === DATA_SOURCE_FUTURES
      ? selectedMonth.symbol
      : FUTURES_TO_INDEX_SYMBOL[symbol];

  const thirtyDayData = historicalMonthData
    .filter((element) => element.symbol === historicalActiveSymbol)
    .flatMap((element) => element.data)
    .filter(
      (element) =>
        isNumber(element.open) &&
        isNumber(element.close) &&
        isNumber(element.high) &&
        isNumber(element.low)
    )
    .map((element) => ({
      x: element.time,
      y: [element.open, element.high, element.low, element.close]
    }));

  // Determine whether we show index data or futures data based on global site setting value.
  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_movement_widget_data_source) ||
    DATA_SOURCE_INDEX;

  const activeSymbol =
    dataSource === DATA_SOURCE_FUTURES
      ? selectedMonth.symbol
      : FUTURES_TO_INDEX_SYMBOL[symbol];

  const oneDayData = Object.keys(candleData)
    .filter((key) => key === activeSymbol + '{=15m}')
    .flatMap((key) => candleData[key])
    .filter(
      (element) =>
        isNumber(element.open) &&
        isNumber(element.close) &&
        isNumber(element.high) &&
        isNumber(element.low)
    )
    .map((element) => ({
      x: element.time,
      y: [element.open, element.high, element.low, element.close]
    }))
    .sort((a, b) => (a.x > b.x ? 1 : -1));

  graphData = graphMode === ONE_DAY ? oneDayData : thirtyDayData;

  const toggle = (
    <ButtonContainer $placement={togglePlacement}>
      <MultiButtonToggle
        options={[
          {
            text: '1 Day',
            selected: graphMode === ONE_DAY,
            onClick: () => {
              setGraphMode(ONE_DAY);
            }
          },
          {
            text: '30 days',
            selected: graphMode === THIRTY_DAYS,
            onClick: () => {
              setGraphMode(THIRTY_DAYS);
            }
          }
        ]}
        fontSize={0.5625}
        minWidth={56}
      />
    </ButtonContainer>
  );

  return (
    <CandlestickContainer style={style}>
      <TitleContainer>{title && <Title>{title}</Title>}</TitleContainer>
      {togglePlacement === 'top' && toggle}
      {isCandleDataLoading ? (
        <SpinnerContainer height='203'>
          <Spinner size='lg' />
        </SpinnerContainer>
      ) : (
        ReactApexChart && (
          <ReactApexChart
            type='candlestick'
            height='188px'
            options={{
              chart: {
                id: 'candle',
                type: 'candlestick',
                height: '188px',
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: false
                },
                animations: {
                  enabled: false
                }
              },
              tooltip: {
                shared: false,
                style: {
                  fontFamily: theme.fonts.default
                },
                x: {
                  format: graphMode === ONE_DAY ? 'HH:mm' : 'MMM d'
                }
              },
              xaxis: {
                type: 'datetime',
                tickPlacement: 'on',
                labels: {
                  show: shouldDisplayAxisLabels,
                  formatter: (value) => {
                    return graphMode === ONE_DAY
                      ? dayjs(value).format('HH:mm')
                      : dayjs(value).format('MMM D');
                  }
                }
              },
              yaxis: {
                labels: {
                  show: shouldDisplayAxisLabels
                },
                max:
                  Math.max(...graphData.map((element) => element.y[1])) + 0.05,
                min:
                  Math.min(...graphData.map((element) => element.y[2])) - 0.05
              },
              plotOptions: {
                candlestick: {
                  wick: {
                    useFillColor: true
                  },
                  colors: {
                    upward: theme.colors.positiveGreen,
                    downward: theme.colors.redPrimary
                  }
                }
              }
            }}
            series={[{ data: graphData }]}
          />
        )
      )}
      {togglePlacement === 'bottom' && toggle}
    </CandlestickContainer>
  );
}

CandlestickGraph.propTypes = propTypes;
CandlestickGraph.defaultProps = defaultProps;

export default CandlestickGraph;
